
/* eslint-disable @typescript-eslint/camelcase */
import { Options, Vue } from "vue-class-component";
import RadioButton from "primevue/radiobutton";
import CustomDropdown from "@/app/ui/components/custom-dropdown/index.vue";
import { CargoController } from "@/app/ui/controllers/CargoController";
import debounce from "lodash/debounce";
import {
  formatDate,
  numberOnly,
  storeDatatoLocalStorage
} from "@/app/infrastructures/misc/Utils";
import { dataLayer } from "@/app/infrastructures/misc/UtilsGtm";
import { gtmCargoData } from "../../modules/gtm";
import { AirportData } from "@/domain/entities/Cargo";
import { ReadyToCargoController } from "@/app/ui/controllers/ReadyToCargoController";
import { OptionsClass } from "@/domain/entities/MainApp";
import { flags } from "@/feature-flags";

@Options({
  emits: ["closePopup"],
  components: {
    CustomDropdown,
    RadioButton
  }
})

export default class ModalPlaneAutoV2 extends Vue {
  // airport
  airportMappingData(data: AirportData[]) {
    return data.map((airport) => ({
      ...airport,
      name: `${airport.airportCode} - ${airport.airportName}`,
      value: airport.airportCode
    }));
  }

  mounted() {
    this.isAllowEditCutoff();
    CargoController.setIsOverCutOffTime(this.isAllowEditCutoff());
  }

  onChangeTotalCargoGrossWeight(value: number) {
    CargoController.setOnChangeTotalActualGrossWeight(value);
  }

  onChangeThresholdTime(value: number) {
    CargoController.setOnChangeThresholdTime(value);
  }

  onChangeTotalCargoVolumeWeight(value: number) {
    CargoController.setOnChangeTotalCargoVolumeWeight(value);
  }

  public setPeriodeStart(date: Date) {
    const estDepartureDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
    const estArrivalDate = this.$moment(date)
      .add(6, "days")
      .toISOString();
    this.form.estDepartureDate = estDepartureDate;
    this.form.estArrivalDate = estArrivalDate;

    const cargoInputForm = JSON.parse(
      localStorage.getItem("cargo-input-data") || "{}"
    );
    cargoInputForm.estDepartureDate = estDepartureDate;
    cargoInputForm.estArrivalDate = estArrivalDate;
    storeDatatoLocalStorage("cargo-input-data", cargoInputForm);
  }

  get showSelectAirport() {
    const isShow = CargoController.showSelectAirport;
    const isUseGtm = isShow && !this.isRtc && this.form.cargoType
    if (isUseGtm) {
      const gtmPopupView = gtmCargoData({
        type: this.form.cargoType,
        isAuto: true
      }).popupView;

      dataLayer(gtmPopupView.eventName, gtmPopupView.property, []);
    }
    return isShow;
  }
  set showSelectAirport(bool: boolean) {
    CargoController.setShowSelectAirport(bool);
  }
  set showSelectAirportToLocalStorage(bool: boolean) {
    CargoController.setShowSelectAirportToLocalStorage(bool);
  }
  get airportOrigin() {
    return CargoController.form.airportOrigin;
  }

  get optionAirportOrigin() {
    return this.airportMappingData(CargoController.airportDataOrigin.data);
  }

  numberOnly(value: string) {
    return numberOnly(value);
  }

  // airport list
  get isPlaneAuto() {
    return (
      this.form.cargoType === "plane" && this.form.cargoTypeStatus === "auto"
    );
  }
  selectAirportOrigin(item: any) {
    if (this.airportOrigin.name == item.name) return
    CargoController.setDefaultAirportOrigin(item);
    const mapName = {
      ...item,
      airportName: `${item.airportCode} - ${item.airportName}`
    };
    if (this.airportsPopupModel.length > 1) {
      this.airportsPopupModel[0] = mapName;
      CargoController.form.airports[0] = mapName;
    } else {
      this.airportsPopupModel.unshift(mapName);
      CargoController.form.airports.unshift(mapName);
    }
  }

  get airportDestination() {
    return CargoController.form.airportDestination;
  }

  get optionAirportDestination() {
    return this.airportMappingData(CargoController.airportDataDestination.data);
  }

  selectAirportDestination(item: any) {
    if (this.airportDestination.name == item.name) return
    CargoController.form.airportDestination = item;
    const mapName = {
      ...item,
      airportName: `${item.airportCode} - ${item.airportName}`
    };
    this.airportsPopupModel[this.airportsPopupModel.length - 1] = mapName;
    CargoController.form.airports[
      this.airportsPopupModel.length - 1
    ] = mapName;
  }

  get cargoData() {
    return CargoController.cargoType;
  }

  get form() {
    return CargoController.form;
  }

  get isRtc() {
    return this.form.isRtc;
  }

  get cargoInputForm() {
    return CargoController.cargoInputForm;
  }

  get isEditCargo() {
    return [0, 1].includes(CargoController.progressValue);
  }

  // transit total
  get airportsPopupModel() {
    return CargoController.airportsPopupModel;
  }

  setAirportPopUpModel(val: any) {
    CargoController.setAirportsPopupModel(val);
  }
  setDefaultDataToAirportPopup() {
    this.setAirportPopUpModel(JSON.parse(JSON.stringify(this.form.airports)));
  }
  cancelPopupAirport() {
    const validationAllowEditCutoff = this.flowBooking !== "rebooking" && !CargoController.isChangeVolumeActual && CargoController.progressValue !== 1
    storeDatatoLocalStorage("is-change-threshold-time", false);
    this.setDefaultDataToAirportPopup();
    this.showSelectAirport = false;
    this.form.isChangeAirport = false;
    this.showSelectAirportToLocalStorage = false;
    this.$emit("closePopup");
    if (validationAllowEditCutoff) {
      this.isAllowEditCutoff();
      CargoController.calculationVolumeWeight(this.form.actualTotalSTTPieces)
    }
  }

  get flowBooking() {
    return ReadyToCargoController.flowBooking;
  }

  isEditCutOff = false;

  isAllowEditCutoff() {
    this.isEditCutOff = this.isEditRtcCutoff && this.isAllowed;
    return this.isEditRtcCutoff && this.isAllowed && this.isPlaneAuto;
  }

  get isEditRtcCutoff() {
    return ReadyToCargoController.flowBooking === "rtc-edit" &&
      this.cutOffTime.hours !== undefined &&
      this.cutOffTime.minutes !== undefined;
  }

  get isAllowed() {
    const today = this.todayTime
    return (+this.cutOffTime.hours < +today.hours ||
        (+this.cutOffTime.hours === +today.hours &&
          +this.cutOffTime.minutes < +today.minutes)) &&
      CargoController.progressValue !== -1;
  }

  get todayTime() {
    return {
      hours: formatDate(new Date())
        ?.split(",")[1]
        ?.split(":")[0]
        ?.trim(),
      minutes: formatDate(new Date())
        ?.split(",")[1]
        ?.split(":")[1]
        ?.replace(/\bwib\b|\bwita\b|\bwit\b|\bgmt\b/gi, "")
        ?.trim()
    };
  }

  get cutOffTime() {
    return {
      hours: ReadyToCargoController.readyToCargoDetail.cutOffTime?.split(
        ":"
      )[0],
      minutes: ReadyToCargoController.readyToCargoDetail.cutOffTime?.split(
        ":"
      )[1]
    };
  }

  get isDisabledAirport() {
    return (
      !this.airportOrigin.name ||
      !this.airportDestination.name ||
      !this.form.estDepartureDate ||
      !parseFloat(
        `${this.form.actualTotalCargoGrossWeight}`.replace(",", ".")
      ) ||
      !parseFloat(
        `${this.form.actualTotalCargoVolumeWeight}`.replace(",", ".")
      ) ||
      this.airportsPopupModel.indexOf("") >= 0 ||
      this.form.thresholdTime < 1 ||
      this.form.thresholdTime > this.tresholdMaxTime
    );
  }
  onChooseButtonAirport() {
    CargoController.resetFlightData(); //reset flightData if choose button lanjut / change schedule on modal plane
    if (this.airportOrigin.name && this.airportDestination.name) {
      CargoController.setPlaneAutoFlow(true);
      this.form.airports = JSON.parse(JSON.stringify(this.airportsPopupModel));
      this.showSelectAirport = false;
      this.form.flightsData = [];
      CargoController.setShowPlaneSchedule(false);
      this.$nextTick(() => {
        CargoController.setShowPlaneSchedule(true);
      });
    }
    if (this.isRtc) {
      CargoController.setProgressValue(1);
    }
    if (
      this.form.cargoType === "plane" &&
      this.form.cargoTypeStatus === "auto"
    ) {
      CargoController.setProgressValue(1);
    }
    this.showSelectAirportToLocalStorage = false;
  }

  get detail() {
    return ReadyToCargoController.readyToCargoDetail;
  }

  get formBooking() {
    return CargoController.form;
  }

  // commodity
  get commodityOptions(): OptionsClass[] {
    return CargoController.commodityOptions;
  }
  get commodityItem() {
    return CargoController.commodityItem;
  }
  onSelectCommodity(val: any) {
    if (val.value === this.commodityItem.value) {
      CargoController.setCommodityItem({
        value: "",
        name: ""
      });
      this.formBooking.cargoCommodityName = "";
      this.formBooking.cargoCommodityCode = "";
      this.formBooking.shcCode = "";
      this.formBooking.shcName = "";
      return;
    }
    CargoController.setCommodityItem(val);
    this.formBooking.cargoCommodityName = val.name;
    this.formBooking.cargoCommodityCode = val.value;
    this.formBooking.shcName = val.shcName;
    this.formBooking.shcCode = val.shcCode;
    const comodity = new OptionsClass({
      name: val.name,
      value: val.value,
      shcCode: val.shcCode,
      shcName: val.shcName
    });
    this.setCargoComodity(comodity);
  }
  setCargoComodity(value: OptionsClass) {
    CargoController.setCargoComodity(value);
  }
  get disableCommodityGroup(): boolean {
    return CargoController.disableCommodityGroup;
  }

  // nog
  onChangeNog(value: string) {
    CargoController.setOnChangeNog(value);
  }

  // shc
  get shcValue() {
    return this.formBooking.shcCode && this.formBooking.shcName
      ? `${this.formBooking.shcCode} - ${this.formBooking.shcName}`
      : "-";
  }

  get isShowFieldWeight() {
    return (
      this.flowBooking === "manual" ||
      this.flowBooking === "rtc-edit" ||
      this.flowBooking === "rebooking"
    );
  }

  get tresholdMaxTime() {
    return flags.cargo_config_flight_treshold_max.getValue();
  }
}
