
import ModalPlaneAuto from "@/app/ui/views/cargo/booking-cargo/components/modal-plane-auto.vue";
import ModalPlaneAutoV2 from "@/app/ui/views/cargo/booking-cargo/components/modal-plane-auto-v2.vue";
import { Options, Vue } from "vue-class-component";
import { FlagsPermissionCargo } from "@/feature-flags/flags-permission-cargo";
@Options({
  components: {
    ModalPlaneAuto,
    ModalPlaneAutoV2,
  },
})
export default class ConfigFeatureFlight extends Vue {
  get flagFeatureFlight() {
    return FlagsPermissionCargo.feature_flight_v2_enable.isEnabled()
  }

  get configFeatureFlight() {
    return this.flagFeatureFlight ? "ModalPlaneAutoV2" : "ModalPlaneAuto"
  }
}
